
import { Options, Vue } from "vue-class-component";
import reCAPTCHA from "@/reCAPTCHA";

@Options({
	computed: {
		isRecaptchaAccepted () {
			// the user already agreed to use reCAPTCHA (loaded)
			return reCAPTCHA.isReady;
		}
	},
})
export default class Registration extends Vue {
	step = 0;
	visible = false; // password is visible or hidden
	exposed = false; // password has been leaked
	taken = false; // username is taken
	user = {
		email: "",
		name: "",
		pwd: "",
		pwd2: "",
	};

	recaptcha_key = process.env.VUE_APP_RECAPTCHA;

	specialEvent = process.env.VUE_APP_EVENT?.trim() ?? ""; // special in-game events

	async mounted (): Promise<void> {
		// already loaded (user returned to this page)
		if (reCAPTCHA.isReady) {
			await this.$nextTick();
			reCAPTCHA.instance.render("recaptcha-container", {
				sitekey: process.env.VUE_APP_RECAPTCHA,
				size: "invisible",
			});
			reCAPTCHA.instance.reset();
		}
	}

	async start (): Promise<void> {
		this.step = -3;

		try {
			await reCAPTCHA.load();
			this.step = 1;
			await this.$nextTick();
			(this.$refs._email as HTMLInputElement).focus();
		} catch (err) {
			this.step = -1
		}
	}

	async checkEmail (): Promise<void> {
		this.step = 2;
		await this.$nextTick();
		(this.$refs._user as HTMLInputElement).focus();
	}

	async checkUser (): Promise<void> {
		// TODO: check here whether the username is taken
		this.step = 3;
		await this.$nextTick();
		(this.$refs._password as HTMLInputElement).focus();
	}

	// TODO: this is not compatible with Edge! we must polyfill
	private async sha1 (text: string) {
		const encoder = new TextEncoder();
		const data = encoder.encode(text);
		const buffer = await self.crypto.subtle.digest("SHA-1", data);
		return this.hexString(buffer);
	}

	// turns a subtlecrypto arraybuffer into a usable hex string
	private hexString (buffer: ArrayBuffer) {
		const byteArray = new Uint8Array(buffer);
		const hexCodes = Array.from(byteArray).map(value =>
			value.toString(16).padStart(2, "0"));

		return hexCodes.join("");
	}

	async checkPassword (): Promise<void> {
		const fullHash = await this.sha1(this.user.pwd);
		const hashPrefix = fullHash.substring(0, 5);
		const hashSuffix = fullHash.substring(5);

		const req = new Request(`https://api.pwnedpasswords.com/range/${hashPrefix}`, {
			mode: "cors",
			cache: "force-cache",
			referrer: "no-referrer",
		});

		const rawResponse = await fetch(req);
		const response: string = await rawResponse.text();

		const found = response.split("\n").some(h => {
			const [hs,] = h.split(":");
			return hashSuffix.toUpperCase() === hs.toUpperCase();
		});

		if (found) {
			// reset the animation
			if (this.exposed) {
				this.exposed = false;
				await this.$nextTick();
			}

			this.exposed = true;
			await this.$nextTick();
			(this.$refs._password as HTMLInputElement).focus();
		} else {
			this.exposed = false;
			this.step = 4;
		}
	}

	sleep (milliseconds: number): Promise<number> {
		return new Promise(resolve => setTimeout(resolve, milliseconds));
	}

	async create (): Promise<void> {
		reCAPTCHA.instance.execute();
		let token = "";

		// the recaptcha API doesn't play nice with Vue
		while (!(token = reCAPTCHA.instance.getResponse())) {
			await this.sleep(1000);
		}

		const req = new Request(`${process.env.VUE_APP_API}/tmwa/account`, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			redirect: "follow",
			referrer: "no-referrer",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-CAPTCHA-TOKEN": token,
			},
			body: JSON.stringify({
				username: this.user.name,
				password: this.user.pwd,
				email: this.user.email,
			}),
		});

		const rawResponse = await fetch(req);
		//const response: string = await rawResponse.text();

		switch (rawResponse.status) {
			// TODO: don't use alerts: embed the error message on the page
			case 201:
				this.step = 5;
				break;
			case 400:
				self.alert("API: malformed request");
				document.location.reload();
				break;
			case 403:
				self.alert("Captcha validation failed.\nPlease try again later");
				document.location.reload();
				break;
			case 409:
				this.taken = true;
				this.step = 2;
				await this.$nextTick();
				(this.$refs._user as HTMLInputElement).focus();
				break;
			case 429:
				self.alert("Too many requests.\nPlease try again later");
				document.location.reload();
				break;
			case 500:
				self.alert("Internal server error.\nPlease try again later");
				document.location.reload();
				break;
			case 502:
				self.alert("Couldn't reach the server.\nPlease try again later");
				document.location.reload();
				break;
			default:
				self.alert(`Unknown error: ${rawResponse.status}`);
				document.location.reload();
				break;
		}
	}
}
